.container_not_found {
  height: 100%;
  width:100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;
}

.container_not_found > section {
  margin-top: 0;
}

.container_not_found > section > h3 {
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  text-align: center;
  text-transform: uppercase;
  color: #505367;
}

.container_not_found > section > p {
  font-size: calc(10px + (16 - 10) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #575757;
}

.container_not_found > button {
  margin-top: 20px;
}

.container {
  width: 100%;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F4F4;
  position: relative;
  margin-top: 40px;
  border-radius: 6px;
  text-align: center;
}

.iconeCheck {
  width: 78px;
  height: 78px;
  position: absolute;
  top: 0;
  margin-top: -38px;
}

.container_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    margin-bottom: 10px;
    text-align: center;
    color: var(--color-black);
  }
}

.footer_content {
  width: 100%;
  height: 42px;
  background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  display:  flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 25px;
  border-radius: 0px 0px 6px 6px;

  span {
    color: var(--color-black);
    font-size: 12px;
  }
}

@media screen and (max-width: 750px) {
  .container {
    height: 222px;
  }

  .container_content {
    padding: 0px 10px;
    h3 {
      line-height: 20px;
      word-break: break-all;
      font-size: 12px;
      margin-bottom: 60px;
    }
  }

  .footer_content {
    height: 94px;
    padding: 10px;
    span {
      font-size: 12px;
    }
  }
}

.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.btnWrap {
  margin-right: 0px;
}

@media screen and (max-width: 720px) {
  .buttons {
    flex-direction: column;
    width: 100%;
  }

  .btnWrap {
    width: 100%;
    margin-right: 0px;
  }
  
}


