.container_payment_confirmation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 0px;
  padding: 10px;
  position: relative;

  > h2 {
    font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.43px;
    text-align: center;

    text-transform: uppercase;
    color: var(--color-secundary);
  }

  > span.productTitle {
    font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;

    color: var(--color-light-grey);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;
  }

  > h3 {
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;

    text-transform: uppercase;
  }

  > p {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: center;

    color: var(--color-grey);
    margin: 15px 0;
  }
}

.actions_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button + button {
    margin-top: 25px;
  }
}

.success_title {
  color: var(--color-primary);
}

.failed_title {
  color: var(--color-error-payment);
}


// -- upsel styles

.container_payment_credit{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.message_payment_confirmation{
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.message_confirmation {
  display: flex; 
  justify-content: center; 
  align-items: flex-start; 
  flex-direction: column;
}

.header_payment_confirmation{
  margin-top: 30px;
  width: 80%;
  height: 70px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title{
  font-size: 20px;
  font-weight: normal;
  color: #00619e;
  margin-bottom: 10px;
}

.money{
  color: #88bd3f;
  font-weight: 700;
  font-size: 20px;
}

.container_boleto_details {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f7ff;
  padding: 15px;

  h3 {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: uppercase;
    text-align: center;
    color: #575757;

    margin-bottom: 10px;

    span {
      color: #00619e;
    }
  }

  hr {
    border: 1px solid #dee3ea;
  }

  strong {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    text-transform: uppercase;
    text-align: center;
    color: #00619e;

    button {
      text-transform: initial;
      color: #575757;
      word-break: break-word;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;

    p {
      width: 50%;

      font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #575757;
    }
  }
}

.price {
  font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300))) !important;
  font-weight: 800 !important;
  letter-spacing: 0.22px !important;
  color: #88bd3f !important;
}

.margin {
  margin: 20px 0 30px 0;
}

.align_center {
  text-align: center;
}

.logo {
  width: 80px;
  height: 25px;
}

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.information_payment{
  width: 80%;
  height: auto;
  background-color: #f8f9f9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px 0px;

  p {
    color: #747a8f;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  @media screen and (max-width: 720px){
    height: 120px;
  }
}

.information_title{
  color: #00619e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.id_session {
  margin: 0px 0px 5px;
}

.id {
  font-weight: bold;
  font-size: 10px;
  color: #00619e;
  margin-right: 5px;
}

.codigo {
  color: #00619e;
  font-size: 10px;
  font-weight: bold;
  word-break: break-all;
}

.id_pix {
  font-size: 14px;
  margin-right: 5px;
  font-weight: 500;
  color: #00619e;
}

.codigo_session {
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
  color: #00619e;
}

.upsell_container{
  width: 705px;
  position: absolute;
  margin-top: 10px;
  margin-left: -5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.17);

  @media screen and (max-width: 720px){
    width: 100%;
    margin-left: -9px;
  }
}

.upsell_header{
  width: 100%;
  height: 50px;
  background-color: #073453;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;

  @media screen and (max-width: 720px){
    border-radius: 0px 0px 0px 0px;
  }
}

.information_upsell {
  width: 80%;
  height: 80px;
  background-color: #f8f9f9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    color: #747a8f;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  @media screen and (max-width: 720px){
    p {
      font-size: 14px;
    }
  }
}

.font_green{
  color: #88bd3f;
}

.buttons_up{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.button_no{
  max-width: 400px;
  height: 50px;
  padding: 5px;
  border: 2px solid #00609e;
  background-color: #fff;
  color: #00609e;
  border-radius: 8px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;

  &:hover{
    background-color: #00609e;
    color: #fff;
    transition: 0.2s ease-in-out;
  }
}

.button_yes{
  max-width: 400px;
  padding: 10px;
  height: 50px;
  background-color: #00609e;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  margin-right: 30px;

  &:hover{
    opacity: 0.7;
    transition: 0.2s ease-in-out;
  }
}

@media screen and (max-width: 720px){
  .button_no, .button_yes {
    width: 95%;
    height: 50px;
    font-size: 12px;
    margin-right: 0px;
  }

  .button_no{
    margin-right: 0px;
    margin: 20px;
  }

  .buttons_up{
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin: 0px 20px;
  }
}

.buttom_accept{
  background-color:#88bd3f;
  min-width: 100%;
  height: 55px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  margin-right: 15px;
  cursor: pointer;

  &:hover{
    opacity: 0.7;
  }
}

.buttom_reject{
  background-color: #b80101;
  min-width: 100%;
  height: 55px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;

  &:hover{
    opacity: 0.7;
  }
}

.container_payment {
  margin-top: 40px;
  width: 100%;
  height: 239px;
  background-color: #F4F4F4;
  border-radius: 6px;

  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.iconeCheck {
  position: absolute;
  top: 0;
  margin-top: -45px;
}

.content_payment_creditCard {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-black);

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    margin-bottom: 25px;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;
  }
}

.footer_content {
  width: 100%;
  height: 42px;
  background-color: #88BD3F;
  position: absolute;
  bottom: 0;
  display:  flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 25px;
  border-radius: 0px 0px 6px 6px;

  span {
    color: #fff;
    font-size: 12px;
  }

  strong {
    color: var(--color-black);
  }
}

@media screen and (max-width: 750px) {
  .container_payment {
    h3 {
      width: 200px;
      line-height: 20px;
      word-break: break-all;
    }

    p {
      margin-bottom: 20px;
    }
  }

  .footer_content {
    span {
      text-align: center;
      font-size: 8px;
      word-break: break-all;
    }
  }
}
