.zapContainer {
    right: 20%;
    position: fixed;
    bottom: 0px;
    z-index: 999;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    transition: 0.2s ease-in-out;

    img {
        width: 76px;
        height: 76px;
        cursor: pointer;
    }

    @media screen and (max-width: 1450px) {
        position: fixed;
        bottom: 0;
        right: 10%;
        margin-bottom: 25px;
        z-index: 999;
        margin-right: 10px;
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 1250px) {
        right: 5%;
    }

    @media screen and (max-width: 880px) {
        right: 0px;

        img {
          width: 80px;
          height: 80px;
          cursor: pointer;
      }
    }

}
