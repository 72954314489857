.contentBoleto {
    width: 100%;
  }
  
.footerWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    margin-top: 20px;
    }