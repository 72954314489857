.bannerTop {
    border-radius: 8px;
    width: 100%;
    margin-top: 20px;
    max-width: 1030px;

    @media screen and (max-width: 1080px) {
      width: 700px;
    }

    @media screen and (max-width: 750px) {
      width: 90%;
    }
  }

  .bannerPromoVertical {
    max-height: 1350px;
    max-width: 330px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 2px;
    border-radius: 16px;

    @media screen and (max-width: 1080px) {
      max-width: 700px;
      padding-top: 12px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 750px) {
      max-width: 90%;
    }
  }

  .containerPromoVertical {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1030px;
    margin-bottom: 20px;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 0px;
      justify-content: flex-start;
      min-height: 100vh;
    }
  }

  .content > div > div {
    @media screen and (max-width: 1080px) {
      min-height: auto;
    }
  }
  
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    min-height: calc(100vh - 50px);
  }

  .header {
    margin-top: 0;
  }

  .firstBanner {
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
  }

  .pitchCustom {
    @media screen and (max-width: 1080px) {
      margin-bottom: 8px !important;
    }
  }

  .content {
      width: 700px;

    @media screen and (max-width: 1080px) {
      width: 100%;
    }
  }