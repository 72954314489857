.close g > path {
  fill: #fff;
}
.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
}

.grow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  flex: 1 1 auto;
}

.message {
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #ffffff;
  text-transform: uppercase;
  flex: 0 1 auto;
  margin-right: 70px;
}
