.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    min-height: calc(100vh - 50px);

    @media screen and (min-width: 820px) {
        margin-bottom: 0;
        overflow: auto;
    }
}

.personalized {
    margin-bottom: 0;
}

.content {
    display: flex;
    align-items: baseline;
    width: 90%;
    padding: 16px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
    margin-bottom: 10px;

  @media screen and (min-width: 820px) {
    width: 700px;
  }
}

.content button {
    margin-top: 10px;
}

.logo {
    width: 120px;
    padding-top: 25px;
}

.header{
    width: 90%;
    height: auto;
    gap: 8px;
    background-color: #88BD3F;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    margin-top: 25px;


    @media screen and (min-width: 820px) {
        width: 700px;
    }
}

.titles {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 8px;
    flex-direction: column;
}

.titles h1, .titles h2 {
    font-size: 14px;
    text-align: center;
    word-break: break-word;

    @media screen and (min-width: 820px) {
        font-size: 16px;
    }
}

.session {
    font-size: 10px;
    text-align: center;
    width: 100%;
}
