.container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.button_accepted{
  width: 130px;
  height: 40px;
  background-color: #88bd3f;
  color: #fff;
  margin-right: 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: .2s ease-in-out;

  &:hover{
    opacity: 0.85;
    transition: .2s ease-in-out;
  }
}

.button_reject{
  width: 130px;
  height: 40px;
  background-color: #D42F26;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;

  transition: .2s ease-in-out;

  &:hover{
    opacity: 0.85;
    transition: .2s ease-in-out;
  }
}

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.footer > *:nth-child(2) {
  margin: 0px 5px;
}

.time {
  width: 100%;
  height: 30px;
  padding: 5px;
  background-color: #88bd3f;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}
