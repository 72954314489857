.orderbump_container{
    width: 700px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
    height: fit-content;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);
}

.imgBag {
    margin-right: 10px;
    margin-left: 10px;
    width: 32px;
    height: 32px;
}

.header {
    width: 100%;
    height: fit-content;
    padding: 5px;
    background-color: #88BD3F;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-radius: 8px 8px 0px 0px;
    text-transform: uppercase;
}

.opt{
    width: 93%;
    height: fit-content;
    background-color: #f8f8f8;
    margin-top:18px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.checkbox{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #063553;
    margin-right: 10px;
    cursor: pointer;
}

.name{
    font-size: 14px;
    color: #767c93;
    font-weight: 500;
}

.font_green{
    font-size: 14px;
    color: #88bd3f;
    font-weight: 500;
}

@media screen and (max-width: 750px){
    .header {
        font-size: 14px;
    }

    .orderbump_container{
        width: 90%;
        height: fit-content;
    }

    .name{
        font-size: 12px;
    }

    .opt {
        height: 100%;
    }
}