.btn, .btnBoleto {
  width: 100%;
  max-width: 322px;
  min-height: 54px;
  padding: 14px 0;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0;
  border-radius: 16px;

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;

  background: var(--color-primary);
  color: var(--color-white);

  transition: opacity 0.2s;

  &:hover {
    opacity: .9;
  }

  &:disabled {
    opacity: 0.34;
    cursor: default;
    background-color: var(--color-light-grey);
  }
}

.btnBoleto {
  background-color: #fff;
  border: 1px solid #88BD3F;
  color: #88BD3F;
}

.outlined {
  background-color: var(--color-white);
  color: var(--color-secundary);
  border: 2px solid var(--color-secundary);
}

@media screen and (max-width: 720px) {
  .btn {
    width: 100%;
    max-width: 100%;
    margin: 10px 0px 10px;
  }
}