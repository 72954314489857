.container_initial {
  width: 100%;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_payment{
  width: 708px;
  height: 100%;
  padding: 13px 10px 50px 9px;
  border-radius: 8px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
  background-color: #fff;
}

.state {
  width: 100%;

  @media screen and (max-width: 720px){
    margin-right: 15px;
  }
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.language{
  color: #7f8293;
  font-weight: 700;
}

.cpf{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cpfMessge{
  margin-top: 20px;
  font-weight: normal;
  color: #940E09;
}

.message{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stylesMessage{
  font-weight: normal;
  color: #C7120C;
}

.infoItem{
  width: 100%;
  height: 108px;
  margin: 0px 0 0;
  padding: 15px 323px 14px 13px;
  border-radius: 11px;
  border: solid 1px #e7e7e7;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
}

.infoItemLoading{
  width: 100%;
  height: 108px;
  border-radius: 11px;
  border: solid 1px #e7e7e7;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: center;
  align-items: center;
}

.detail{
  color: #00619e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 100%;
}

.details{
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
}

.title{
  color: #555a74;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.ministering{
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: #555a74;
}

.money{
  color: #88bd3f;
  font-weight: 700;
  font-size: 20px;
}

.card{
  font-weight: 700;
  color: #555a74;
  font-size: 12px;
}

.personalData{
  margin: 20px 10px;
}

.personal_title{
  color: #00619e;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 20px;
}

.form{
  margin: 15px 0;
  margin-top: -5px;
}

.serachPostalCode{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media(max-width: 720px) {
  .card_payment {
    width: 100%;
    border-radius: 0px;
  }

  .infoItem {
    padding: 10px 0px 0px 10px;
  }

  .infoItemLoading {
    padding: 0px 0px 0px 0px;
  }

  .detail {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    font-size: 15px;
  }

  .money {
    font-size: 15px;
  }
}

.content_initial {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  // @media(max-width: 1045px) {
  //   flex-direction: column;
  //   margin-top: 15px;
  // }
}

.logo {
  width: 80px;
  height: 25px;
}

.title_cpf {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 5px;
}

.title_cpf_style {
  color: #00619e;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 10px;
}

.grid {
  width: 100%;
  
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 720px) {
    flex-direction: row;

    margin-top: 5px;

    > div + div {
      padding-left: 25px;
    }
  }
}

aside {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  padding: 25px;
  margin-right: 30px;
  background: var(--color-white);
  border-radius: 8px;

  div.content_initial_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    div.content_initial_header_title {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      h2 {
        font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        text-align: center;
        color: var(--color-grey);
        text-transform: uppercase;

        > span {
          color: var(--color-secundary);
        }
      }

      p {
        font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
        font-weight: bold;
        letter-spacing: 0.16px;
        text-align: center;
        color: var(--color-grey);

        > strong {
          font-weight: 800;
        }
      }
    }
  }

  div.content_initial_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      width: 311px;
      height: 350px;

      margin: 30px 0 38px 0;
      border-radius: 8px;
      background-image: linear-gradient(to left, var(--color-secundary) 0%, var(--color-primary) 100%);

      img {
        margin: 10px 0 0 10px;
        border-radius: 8px;

        width: 325px;
        height: 364px;
      }

      @media(max-width: 768px) {
        width: 231px;
        height: 259px;

        img {
          width: 241px;
          height: 270px;
        }
      }

      @media(max-width: 425px) {
        width: 162px;
        height: 183px;

        img {
          width: 166px;
          height: 187px;
        }
      }
    }

    h3 {
      font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      text-align: center;
      color: var(--color-grey);
      text-transform: uppercase;
    }

    article {
      font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 0.16px;
      text-align: center;
      color: var(--color-grey);

      margin: 12px 0 35px 0;
    }

    section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      text-align: center;
      color: var(--color-secundary);

      > span {
        font-size: calc(20px + (34 - 20) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 800;
        letter-spacing: 0.81px;
      }

      > small {
        font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
        font-weight: bold;
        letter-spacing: 0.34px;
      }
    }
  }

  @media(max-width: 1045px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

main {
  height: calc(100vh - 255px);
  overflow-y: auto;

  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  padding: 25px;
  background: var(--color-white);
  border-radius: 8px;

  &.align_center {
    justify-content: center;
  }
}

.img_arrow_down {
  position: absolute;
  right: 0;

  opacity: .31;
  cursor: pointer;
}

.rotate_180 {
  transform: rotate(180deg);
}

.hidden {
  display: none !important;
}

.main {
  display: flex;
  flex-direction: column;
}