.container_steps {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px 20px;

  background: var(--color-white);

  @media(min-width: 1046px) {
    max-width: 85%;
  }

  @media(min-width: 1500px) {
    max-width: 70%;
  }

  @media(max-width: 600px) {
    width: 80%;
  }
}

.section_content {
  width: 100%;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 10px;

  @media(max-width: 1045px) {
    flex-direction: column;
  }
}

.number_wrapper {
  padding: 4px 6px;
  border-radius: 8px;

  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;

  color: var(--color-white);
  background-color: var(--color-light-grey);
  opacity: .25;

  margin-right: 6px;
}

.number_active {
  background-color: var(--color-secundary);
  opacity: 1;
}

.text_wrapper {
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  text-transform: uppercase;
  text-align: center;

  color: var(--color-light-grey);
  opacity: .25;

  @media(min-width: 1046px) {
    white-space: nowrap;
  }
}

.text_active {
  color: var(--color-secundary);
  opacity: 1;
}

.divider {
  content: '';
  width: 100%;
  height: 0;
  border: solid 2px var(--color-light-grey);
  background-color: var(--color-light-grey);
  opacity: .25;
}

.divider_active {
  width: 50px;
  border: solid 2px var(--color-secundary);
  background-color: var(--color-secundary);
  opacity: 1;
}
