.container_tabs {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.container_tabs section {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
}

.container_tabs ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.container_tabs_hidden {
  width: 100%;
}

.container_tabs_hidden ul {
  display: none;
}

.container_tabs ul li {
  width: 100%;
  height: 53px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 7px;
  border: 1px solid #e0e4e9;
  background-color: #fff;
  color: var(--color-primary);
  cursor: pointer;

  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
}

.container_tabs ul li:not(:first-child) {
  margin-left: 25px;
}

.container_tabs ul li img {
  margin-right: 18px;
  fill: #d1d5da;
  color: #fff;
  margin-right: 10px;
}

.container_tabs ul li.active {
  color: #fff;
  background-color: var(--color-primary);
}

.container_tabs ul li.active svg {
  fill: #fff;
}

@media(max-width: 750px) {
  .container_tabs ul {
    flex-direction: column;
  }

  .container_tabs ul li {
    margin-bottom: 10px;
    font-size: calc(10px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));

    &:not(:first-child) {
      margin-left: 0px;
    }
  }
}

.container_tabs .disabled {
  cursor: not-allowed;
}