.container_error {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.logo {
  margin-top: 20px;
}

.content_error {
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 8%;

  h1 {
    font-size: 160px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.83px;
    color: var(--color-secundary);
  }

  p {
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.41px;
    text-align: center;
    color: var(--color-grey);
  }
}