.container_select {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin-top: 5px;
  
  @media screen and (max-width: 720px) {
    height: 80px;
    margin-top: -5px;
  }
}

.title_select {
  margin: 3px 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-black);

  p {
    font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
  }

  small {
    margin-left: 6px;
    font-size: calc(8px + (10 - 8) * ((100vw - 300px) / (1600 - 300)));
    color: var(--color-primary);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.content_select {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;

  background: var(--color-white);
  border-radius: 6px;
  border: 1px solid #BCC4CE;
  color: var(--color-grey);

  & + div {
    margin-top: 8px;
  }

  img {
    position: absolute;
    top: 14px;
    right: 10px;
    z-index: 9;
    cursor: pointer;
  }

  select {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;

    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    z-index: 10;
    cursor: pointer;

    option {
      color: #575757;
    }

    &:disabled {
      cursor: initial;
      border: 0px solid #BCC4CE;
    }

    @media screen and (max-width: 720px) {
      font-size: 13px;
      margin-right: 15px;
    }
  }

  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
    -webkit-text-fill-color: var(--color-grey) !important;
  }
}

.isFocused {
  color: var(--color-primary);
}

.isErrored {
  border-color: var(--color-error);
  height: 38px;
}

.isDisabled {
  background-color: #F5F5F5;
  border: 0;
}

.sectionError {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.error_caption {
  margin: 0px 10px;
  font-size: calc(8px + (12 - 10) * ((100vw - 200px) / (1600 - 300)));
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: right;
  margin-top: -5px;

  color: var(--color-error-input);
}

@media screen and (max-width: 750px) {
  .content_select {
    select {
      font-size: 16px;
    }
  }
}
