.container_pix {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 15px 0px;
}

.qrcode_expired{
  opacity: 0.2;
  position: absolute;
  width: 206px;
  height: 206px;
  background-position: center;
  background-size: cover;

}

.icon{
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
  color: #f4f7ff;
}

.new_qrcode{
  width: 226px;
  height: 226px; 
  background-color: #f4f7ff; 
  margin-top: 20px;
  border: 8px solid var(--color-primary);
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    opacity: 0.9;
  }
}

.qrcode {
  width: 206px;
  height: 206px;
  background-position: center;
  background-size: cover;
}

.circle{
  width: 178px;
  height: 178px;
  border-radius: 50%;
  background-color:var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 14px;
  margin-top: 14px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;

  &:hover{
    background-color: var(--color-primary);
    transition: 0.2s ease-in-out;
  }
}

.copyName {
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color:  #fff;
  margin-left: 5px;
}

.message_circle{
  color: #f4f7ff;
  font-size: 12px;
  width: 100px;
  text-align: center;
  font-weight: bold;
}

.message_expired{
  font-size: 18px;
  font-weight: 500;
  color: #505367;
}

.subtitle {
  font-size: calc(14px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  color: var(--color-black);
  text-align: center;
  word-break: break-all;
}

.borderImg{
  border: 8px solid var(--color-primary);
  border-radius: 8px;
  width: 226px;
  height: 226px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.footer{
  margin-top: 10px;
  margin-bottom: 30px;

  color: #B1A5A5;
  span {
    color: #88bd3f;
    margin-left: 5px;
  }
}


.link_qr_code{
  width: 100%;
  height: 170px;
  padding: 15px 30px;
  background-color: #F4F4F4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 15px;

  p {
    text-align: center;
    margin-bottom: 10px;
    word-break: break-word;
  }
}

.text_copy {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;

  span {
    color: var(--color-black);
    font-size: 12px;
    font-weight: 500;
    word-break: break-all;
    display: block;
    text-align: center;
  }
}

.qrcode_origin {
  width: 206px;
  height: 206px;
  background-position: center;
  background-size: cover;
}

.box_code {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  margin: 15px 10px;

  border-radius: 8px;
  background-color: #f4f7ff;

  > p {
    font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;

    text-transform: uppercase;
    text-align: center;
    color: #505367;

    margin-right: 10px;
  }
}

.copy_button {
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);

  border-radius: 6px;
  border: 1px solid var(--color-primary);
  cursor: pointer;

  padding: 8px;
  margin-left: 10px;
}

.alert {
  display: flex;
  align-items: center;

  margin-bottom: 20px;

  span {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;

    text-transform: uppercase;
    text-align: center;
    color: #505367;

    strong {
      font-weight: 800;
      color: #88bd3f;
    }
  }

  img {
    margin-right: 10px;
  }
}

@media(max-width: 700px){
  .link_qr_code{
    word-break: break-all;
    height: 150px;
  }

  .container{
    font-size: 14px;
  }

  .qrcode{
    width: 206px;
    height: 206px;
  }
}

@media(max-width: 550px){
  .link_qr_code{
    height: 200px;
    padding: 20px
  }

  .subtitle{
    margin-bottom: 20px;
  }

  .link_qr_code span {
    font-size: 11px;
  }
}

@media(max-width: 430px){
  .link_qr_code{
    height: 120px;
    font-size: 10px;
    padding: 6.5px 15px;
    margin-bottom: 20px;
  }
}

.container_first {
  width: 100%;
  height: 306px;
  background-color: #F4F4F4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:  column;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  margin-top: 20px;
  text-align: center;
}

.message {
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .message {
    flex-direction: column;
  }

  .link_qr_code {
    height: 256px;
    padding: 20px; 

    p {
      margin-bottom:  20px;
      font-size: 12px;
    }
  }

  .copy_button {
    width: 100%;
  }

  .link_qr_code {
    justify-content: center;
  }

  .text_copy {
    margin-bottom: 10px;

    span  {
      font-size: 10px;
    }
  }

  .container_first {
    height: 330px;
  }
}