.container_details {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f7ff;
  padding: 15px;
  cursor: default;

  h3 {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: uppercase;
    text-align: center;
    color: #575757;

    margin-bottom: 10px;

    span {
      color: #00619e;
    }
  }

  hr {
    border: 1px solid #dee3ea;
  }

  strong {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    text-transform: uppercase;
    text-align: center;
    color: #00619e;

    button {
      text-transform: initial;
      color: #575757;
      word-break: break-word;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;

    padding: 8px 0;

    p {
      width: 50%;

      font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #575757;
    }
  }
}

.price {
  font-size: calc(15px + (19 - 15) * ((100vw - 300px) / (1600 - 300))) !important;
  font-weight: 800 !important;
  letter-spacing: 0.22px !important;
  color: #88bd3f !important;
}

.margin {
  margin: 20px 0 30px 0;
}

.align_center {
  text-align: center;
}
