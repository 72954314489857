.container_footer {
  width: 100%;
  height: 50px;
  background: #EAEAEA;

  position: relative;
  bottom: 0;
  left: 0;

  z-index: 998;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    text-align: center;
  }

  a {
    font-weight: bold;
    color: #89BC3F;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
