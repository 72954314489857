.container_loading {
  width: 100%;
  height: 100vh;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
}