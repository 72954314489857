.Toastify__toast-container--top-right {
  right: 6em !important;
}

.Toastify__toast {
  width: 400px;
  border-radius: 11px !important;
  padding: 0 !important;
  min-height: 51px;
}

.Toastify__toast--success {
  background-color: #88bd3f !important;
  -webkit-box-shadow: 0 8px 0px -6px #6e9b30 !important;
  -moz-box-shadow: 0 8px 0px -6px #6e9b30 !important;
  box-shadow: 0 8px 0px -6px #6e9b30 !important;
}

.Toastify__toast--error {
  background-color: #e66b7c !important;
  -webkit-box-shadow: 0 8px 0px -6px #dc3c52 !important;
  -moz-box-shadow: 0 8px 0px -6px #dc3c52 !important;
  box-shadow: 0 8px 0px -6px #dc3c52 !important;
}

.Toastify__toast-body {
  width: 100%;
  padding: 10px !important;
}
