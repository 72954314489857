.container_input {
  height: calc(80px + (86 - 80) * ((100vw - 300px) / (1600 - 300)));
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -15px;
  width: 100%;

  @media(max-width: 600px){
    margin-top: -5px;
  }
}

.title_input {
  margin: 3px 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-black);

  p {
    font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
  }

  small {
    margin-left: 6px;
    font-size: calc(8px + (10 - 8) * ((100vw - 300px) / (1600 - 300)));
    color: var(--color-black);
  }
}

.isFocused {
  color: var(--color-black);
}

.isErrored {
  border-color: var(--color-error);
}

.content_input {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;

  color: var(--color-grey);
  background: var(--color-input);

  border: 1px solid #BCC4CE;
  border-radius: 6px;

  position: relative;

  & + div {
    margin-top: 8px;
  }

  img {
    position: absolute;
    right: 10px;
  }

  &:read-only, &:disabled {
    border: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--color-white) inset !important;
    -webkit-text-fill-color: var(--color-grey) !important;
  }

  .inputStyle, .inputPrice {
    width: 100%;
    height: 100%;
    background: var(--color-white);

    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;

    border-radius: 6px;
    border: solid 1px var(--border-input);
    outline: none;
    color: var(--color-grey);

    padding: 7px 30px 7px 9px;
  }

  .inputStyle:focus {
    border-color: var(--color-primary);
  }

  .inputStyle::placeholder {
    color: var(--color-grey);
    opacity: 0.5;
  }

  .inputStyle:disabled {
    opacity: 0.5;
    border-color: var(--disabled-input);
  }

  .inputStyle.error {
    border-color: red;
  }

  .inputPrice {
    color: var(--color-primary);
    font-weight: 700;
  }

  .inputPrice:disabled {
    opacity: 0.5;
    border-color: var(--disabled-input);
  }
  
}
.error_caption {
  margin: -5px 10px;

  font-size: calc(8px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: right;

  color: var(--color-error-input);
}

.positionError {
  width: 100%;
}

@media screen and (max-width: 750px) {
  .content_input {
    .inputStyle, .inputPrice {
      font-size: 16px;
    }
  }
}
