.container_payment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px 10px;

  > form {
    width: 100%;
  }
}

.personal_title{
  color: #00619e;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 20px;
}

.container_payment h1 {
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;

  color: var(--color-secundary);
  text-transform: uppercase;
  text-align: center;
}

.container_payment span.productTitle {
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;

  color: var(--color-light-grey);
  text-transform: uppercase;
  text-align: center;

  margin-bottom: 30px;
}

.container_payment h2 {
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;

  text-align: center;
  text-transform: uppercase;
  color: var(--color-light-grey);

  margin: 0 10px 30px 10px;
}