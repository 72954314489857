.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 50px);

  @media screen and (min-width: 820px) {
    overflow: auto;  
  }
}

.personalized {

  @media screen and (max-width: 820px) {
    min-height: auto;
  }

}

.logo {
    width: 110px;
    padding-top: 15px;

    @media screen and (min-width: 820px) {
      padding-top: 30px;
      width: 120px;
  }
}

.content {
    display: flex;
    align-items: baseline;
    width: 90%;
    padding: 16px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
    border-radius: 8px;
    margin-bottom: 30px;
    margin-top: 25px;

  @media screen and (min-width: 820px) {
    width: 700px;
  }
}