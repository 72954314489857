.container_input_credit_card {
  height: 46px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

.brand {
  background-color: #000;
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1em;
  position: absolute;
  right: 10px;
}

.title_input_credit_card {
  margin: 3px 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-black);

  p {
    font-size: calc(10px + 2 * (100vw - 300px) / 1300);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}

.isFocused {
  color: var(--color-primary);
}

.isErrored {
  border-color: var(--color-error);
}

.content_input_credit_card {
  width: 325px;
  display: flex;
  align-items: center;

  color: var(--color-grey);
  background: var(--color-input);

  border: 1px solid #F5F5F5;
  border-radius: 6px;

  position: relative;

  svg {
    position: absolute;
    right: 10px;
  }

  & + div {
    margin-top: 8px;
  }

  input {
    width: 100%;
    height: 40px;
    background: var(--color-white);

    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;

    border-radius: 6px;
    border: solid 1px var(--border-input);

    outline: none;
    color: var(--color-grey);

    padding: 7px 30px 7px 9px;

    &:focus {
      border-color: var(--color-primary);
    }

    &::placeholder {
      color: var(--color-grey);
      opacity: 0.3;
    }

    &:read-only, &:disabled {
      opacity: 0.5;
      border: 0;
    }
  }
}
.sectionError {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.error_caption {
  margin: -10px 10px;

  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;

  color: var(--color-error-input);
}


@media screen and (max-width: 750px) {
  .content_input_credit_card {
    width:  100%;

    input {
      font-size: 16px;
    }
  }
}

@media not all and (min-resolution:.001dpcm) { /* Ignorar outros navegadores */
  @supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) {
    /* Aplicar estilos apenas para o Safari */
    .container_input_credit_card {
      height: 80px;
    }
  }
}
