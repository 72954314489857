header {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 1046px) {
    flex: 1;
  }
}

.content_up {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_down {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: center;
}

.question_wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  img {
    margin-right: 10px;
  }

  p {
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: var(--color-dark-grey);
  }
}

.clock_section {
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media(max-width: 1045px) {
    margin: 0 20px;
  }
}