 .tooltip {
   max-width: 50%;
   border-radius: 7px !important;
   box-shadow: 0px 2px 2px lightgray !important;

  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300))) !important;
  font-weight: 800 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.34px !important;
  text-align: center !important;
  color: #505367 !important;
  text-transform: uppercase !important;

  opacity: 1 !important;
 }
