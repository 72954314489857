.form_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > section {
    width: 100%;
  }
  

  .with_two_cards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    margin-bottom: 5px;
    border: 1px solid  #BCBCBC;

    border-radius: 8px;
    background-color: #fff;

    > p {
      font-size: calc(12px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.14px;

      text-align: center;
      color: var(--color-black);

      margin-right: 10px;
    }
  }

  .second_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bold {
    font-size: calc(12px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;
    color: var(--color-primary);
    padding-left: 10px;
    background-color: var(--color-white);
    height: 100%;
    width: 100%;
    border: 0.2px solid #BCC4CE;
    border-radius: 6px;
  }

  .price {
    font-size: calc(12px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;
    color: var(--color-primary);
    padding-left: 10px;
    background-color: var(--color-white);
    height: 100%;
    width: 100%;
    border: 0.2px solid #BCC4CE;
    border-radius: 6px;
  }
}

.border_color_error input,
.border_color_error input:read-only {
  border: 1px solid var(--color-error-input);
}

.error_msg_two_cards {
  font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-error-input);

  margin-bottom: 20px;
}

.grid {
  width: 100%;
  
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;

  @media(min-width: 720px) {
    flex-direction: row;

    margin-top: 5px;

    > div + div {
      padding-left: 25px;
    }
  }
}

.grid_security {
  width: 100%;
  
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 720px) {
    flex-direction: row;

    margin-top: 5px;

    > div + div {
      padding-left: 25px;
    }
  }
}

.footer_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.campos {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.errorMessage {
  color: var(--color-error);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin: 10px 0px;
}

.switch {
    /* Estilo de fundo */
    background-color: #e5e5ea;
    border: none;
  
    /* Estilo do botão */
    .react-switch-handle {
      background-color: #fff;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
    }
  
    /* Estilo do botão quando ativado */
    &.react-switch--checked .react-switch-handle {
      background-color: #007aff;
    }
}

.subTitleFirstCard, .subTitleSecondCard {
  width: 100%;
}

.subTitleFirstCard {
  margin-top: 10px;
}

.oneCard {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
}

.separator, .separatorTwoCard {
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  margin-bottom: 20px;
}

.separatorTwoCard {
  margin-bottom: 20px;
}

.grid_new {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.CreditCardNumber {
  margin-bottom: 0px;
  width: 100%;
}

.securityInfos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
}

.securityInfos > :first-child {
  margin-right: 10px;
}

@media screen and (max-width: 750px) {
  .grid_new {
    flex-direction: column;

  }

  .securityInfos {
    margin-left: 0px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .CreditCardNumber {
    margin-bottom: 15px;
  }

  .securityInfos > :first-child {
    margin-right: 10px;
  }

  .separator {
    margin-bottom: 10px;
  }
}
